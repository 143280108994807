/* html,body {
background-color: green;
}
.Output{
  background-color: yellow;

} */
df-messenger {
   --df-messenger-bot-message: #ffebee;
   --df-messenger-button-titlebar-color: #9e9e9e;
   --df-messenger-chat-background-color: #eeeeee;
   --df-messenger-font-color: #004d40;
   --df-messenger-send-icon: #878fac;
   --df-messenger-user-message: #e8f5e9;
  }

.App {
  /* text-align: center; */
  /* height:100vh; */
  /* width:100vw;
  height:100%; */
  color: #59656fff;
  background-color: #d4cbe5ff;
  /* align-items: center;
  justify-content: center; */
  /* width:100%; */
}


.App-list {
  /* background-color: blue; */
  /* overflow-x: hidden;
  overflow-y:hidden; */
  /* min-height:80vh; */
  /* display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* display:flex;
  align-items: center;
  justify-content: center; */
  
}

.App-header {
  /* background-color:yellow; */
  /* width: 100vw;
  min-height: 10vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center;
  justify-content: center; */

}

.App-link {
  color: #61dafb;
}
